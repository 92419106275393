<template>
	<div class="obe-login login-content-centered">
		<div class="obe-login__wrapper">
			<div class="v-banner__logo-login">
				<img src="/images/header-logo.svg" alt="Logo" class="v-banner__logo"/>
			</div>
			<fade-transition>
				<div v-if="status == 'start'">
					<layout row wrap>
						<flex md6 xs12>
							<div class="obe-login__title-container">
								<p class="obe-login__title">Welcome to your AspenClean's Dashboard</p>
								<p class="obe-login__subtitle">Sign in to Start</p>
							</div>
							
							<div v-if="loginFail" class="obe-alert obe-alert--error obe-mb-6">
								Invalid email address or password!
							</div>					

							<obe-form ref="form">
								<div class="obe-login__input-label-container">
									<label class="obe-login__input-label">Email Address*</label>
								</div>
								<text-field
									autofocus
									hide-label
									placeholder="Email Address"
									v-model.trim="email"
									:rules="rules.email"
									@enter="submit">
								</text-field>

								<div class="obe-login__input-label-container">
									<label class="obe-login__input-label">Password*</label>
								</div>
								<text-field
									hide-label
									placeholder="Password"
									input-type="password"
									v-model.trim="password"
									:rules="rules.password"
									@enter="submit">
								</text-field>
							</obe-form>
						</flex>
					</layout>

					<layout row wrap v-show="enableRecaptcha">
						<flex md6 mdpush6>
							<div class="obe-db-subheading">Please complete the captcha below to proceed</div>
						</flex>
						<flex md6 class="obe-mb-4">
							<div class="obe-g-recaptcha" ref="recaptcha" data-size="compact"></div>
						</flex>
					</layout>

					<layout class="obe-login__buttons">
						<flex md6>
							<div class="obe-login__links">
								<router-link to="/forgot_password" class="obe-link-gray">Forgot Password?</router-link><br>
								<!-- <router-link to="/forgot_email" class="obe-link">Forgot email address?</#router-link> -->
							</div>
							<push-button block label="Login" @click="submit" :loading="loading"></push-button>
						</flex>
					</layout>
				</div>

				<!-- step 2: code sent to phone/code was rejected -->
				<div v-else-if="status == 'code-sent'">
					<div v-if="status == 'code-sent'" class="obe-login__body">Please enter the code we sent you</div>
					<div v-if="status == 'code-rejected'" class="obe-login__body">The code you entered was invalid or has expired</div>

					<obe-form ref="twofactorForm">
						<layout row wrap>
							<flex md6>
								<text-field
									hide-label
									placeholder="6 digit code"
									input-type="number"
									v-model="code"
									:rules="rules.code">
								</text-field>
							</flex>

							<flex md6>
								<push-button block color="orange" @click="verifyCode" :loading="loading">Verify</push-button>
							</flex>
						</layout>
					</obe-form>
				</div>
			</fade-transition>
		</div>
	</div>
</template>

<script>
import alerts from 'obe/shared/alerts';
import api from 'obe/dashboard/api';
import { appConfig } from 'obe/config';

export default {
	data()
	{
		return {
			email: '',
			password: '',
			status: 'start',
			loading: false,
			code: '',
			loginFail: false,
			enableRecaptcha: false,

			rules: {
				email: [ v => !!v || 'Please enter your email address' ],
				password: [ v => !!v || 'Please enter your password' ],
				code: [ 
					v => !!v || 'Please enter the code we sent you',
					v => v.length == 6 || 'Code must be six digits long'
				],
			}
		}
	},

	methods: {
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			if(this.enableRecaptcha){
				var recaptcha = grecaptcha.getResponse();
				if (!recaptcha)
				{
					alerts.show('Please complete the recaptcha', '', 'warning');
					return;
				}
			}

			this.loading = true;
			this.loginFail = false;

			try
			{
				var params = { email: this.email, password: this.password, recaptcha_response: recaptcha };
				var result = await api.post('/auth/login', params);

				if (result.data.ok)
				{
					this.status = 'code-sent';
				}
				else
				{
					this.loginFail = true;
					this.enableRecaptcha = result.data && result.data.enable_recaptcha;
				}
			}
			finally
			{
				grecaptcha.reset();
				this.loading = false;
			}
		},

		async verifyCode()
		{
			if (!this.$refs.twofactorForm.validate())
				return;

			this.loading = true;

			try
			{
				var params = { code: this.code, email: this.email };
				var result = await api.post('/auth/verify_two_factor', params);

				if (result.data.ok)
				{
					if (this.$route.query.redirect)
					{
						window.location = this.$route.query.redirect;
					}
					else
					{
						window.location = '/dashboard/schedule';
					}
				}
				else
				{
					alerts.show(result.data.error, '', 'warning');
					this.status = 'code-sent';
				}
			}
			finally
			{
				this.loading = false;
			}
			
		}
	},

	mounted()
	{
		var rc = this.$refs.recaptcha;
		var key = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

		grecaptcha.render(rc, { sitekey: key });
	},
}
</script>
